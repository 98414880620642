var InitWaypointAnimations = (function () {
    function onScrollInitAnimation(items, container, options) {
        const containerOffset = ( container ) ? container.attr("data-animation-offset") || options.offset : null;
        items.each( function() {
            const element = $(this),
            animationClass = element.attr("data-animation"),
            animationDelay = element.attr("data-animation-delay") || options.delay,
            animationDuration = element.attr("data-animation-duration") || options.duration,             
            animationOffset = element.attr("data-animation-offset") || options.offset;
            animationEase = element.attr("data-animation-ease") || options.ease;
            
            element.css({
                "-webkit-animation-delay":  animationDelay,
                "-moz-animation-delay":     animationDelay,
                "animation-delay":          animationDelay,
                "-webkit-animation-duration":  animationDuration,
                "-moz-animation-duration":     animationDuration,
                "animation-duration":          animationDuration,
                "-webkit-animation-timing-function":  animationEase,
                "-moz-animation-timing-function":     animationEase,
                "animation-timing-function":          animationEase,
                "opacity":                  0
            });

            const trigger = ( container ) ? container : element;

            trigger.waypoint(function(direction) {
                // console.log(direction);
                if (direction == 'down') {
                    element
                        .addClass("animated")
                        .addClass(animationClass)
                        .css({
                            "opacity": 1
                        });
                } else {
                    element
                        .removeClass("animated")
                        .removeClass(animationClass)
                        .css({
                            "opacity": 0
                        });
                }
                
            },{
                // triggerOnce: true,
                offset: containerOffset || animationOffset
            });
        });
    }
    
    function InitWaypointAnimations(defaults) {
        if(!defaults) { defaults = {}; }
        const options = {
            offset: defaults.offset || "90%",
            delay: defaults.delay || "0s",
            duration: defaults.duration || "1s",
            ease: defaults.ease || "linear",
            animateClass: defaults.animateClass || "ez-animate",
            animateGroupClass: defaults.animateGroupClass || "ez-animate-group"
        }

        const animateGroupClassSelector = classToSelector(options.animateGroupClass);
        const animateClassSelector = classToSelector(options.animateClass);

        // Attach waypoint animations to grouped animate elements
        $(animateGroupClassSelector).each(function (index, group) {
            var container = $(group);
            var items = $(group).find(animateClassSelector);
            onScrollInitAnimation(items, container, options);
        });

        // Attach waypoint animations to ungrouped animate elements
        $(animateClassSelector).filter(function (index, element) {
            return $(element).parents(animateGroupClassSelector).length === 0;
        }).each(function (index, element) {
            onScrollInitAnimation($(element), null, options);
        });
    }

    function classToSelector(className) {
        return "." + className;
    }

    return InitWaypointAnimations;
}());