$(document).foundation();

// var md = new MobileDetect(window.navigator.userAgent);
var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

var PATH = {};

if (window.location.hostname.indexOf(".com") > -1) {
 	PATH = {
		images: '/application/themes/grandbeach/assets/images',
		scripts: '/application/themes/grandbeach/assets/js',
		styles: '/application/themes/grandbeach/assets/css'
	};
} else {
	PATH = {
		images: 'images',
		scripts: 'js',
		styles: 'css'
	};
}

var Helper = (function() {

	var doAnimations = function(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
                $this.removeClass($animationType);
            });
        });
    }

    return {
    	doAnimations: doAnimations
    }

})();

// var mobileCheck = {
// 	ios: (function(){
// 		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
// 	}()),
// 	android: (function(){
// 		return navigator.userAgent.match(/Android/i);
// 	}()),
// 	blackBerry: (function(){
// 		return navigator.userAgent.match(/BB10|Tablet|Mobile/i);
// 	}()),
// 	windows: (function(){
// 		return navigator.userAgent.match(/IEMobile/i);
// 	}()),
// 	smartphone: (function(){
// 		return (window.innerWidth <= 384 && window.innerHeight <= 640);
// 	}()),
// 	tablet: (function(){
// 		return (navigator.userAgent.match(/Tablet|iPad|iPod/i) && window.innerWidth <= 1280 && window.innerHeight <= 800);
// 	}()),
// 	all: (function(){
// 		return navigator.userAgent.match(/Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i);
// 	}())
// };
// 
var mobileCheck = {
	ios: function(){
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	android: function(){
		return navigator.userAgent.match(/Android/i);
	},
	blackBerry: function(){
		return navigator.userAgent.match(/BB10|Tablet|Mobile/i);
	},
	windows: function(){
		return navigator.userAgent.match(/IEMobile/i);
	},
	smartphone: function(){
		return (window.innerWidth <= 384 && window.innerHeight <= 640);
	},
	tablet: function(){
		return (navigator.userAgent.match(/Tablet|iPad|iPod/i) && window.innerWidth <= 1280 && window.innerHeight <= 800);
	},
	all: function(){
		return navigator.userAgent.match(/Android|BlackBerry|Tablet|Mobile|iPhone|iPad|iPod|Opera Mini|IEMobile/i);
	}
};

var userAgent = window.navigator.userAgent;

var isBadStockAndroid = (function() {
	// Android stock browser test derived from
	// http://stackoverflow.com/questions/24926221/distinguish-android-chrome-from-stock-browser-stock-browsers-user-agent-contai
	var isAndroid = userAgent.indexOf(' Android ') > -1;
	if (!isAndroid) {
		return false;
	}

	var isStockAndroid = userAgent.indexOf('Version/') > -1;
	if (!isStockAndroid) {
		return false;
	}

	var versionNumber = parseFloat((userAgent.match('Android ([0-9.]+)') || [])[1]);
	// anything below 4.4 uses WebKit without *any* viewport support,
	// 4.4 has issues with viewport units within calc()
	return versionNumber <= 4.4;
})();

var Devices = (function($, mobileCheck){

	var $html = $('html');

	

	var init = function() {
		initDevices();

		$(window).on('resize.device', 
			function(){
				reset();
				// setTimeout(function(){				
				initDevices();
				// }, 500);			
			}
		);
	}

	function initDevices() {
		// console.log(mobileCheck.all());
		if (mobileCheck.ios()) {
			$html.addClass('ios');
		}
		if (mobileCheck.android()) {
			$html.addClass('android');
		}
		if (mobileCheck.blackBerry()) {
			$html.addClass('blackBerry');
		}
		if (mobileCheck.windows()) {
			$html.addClass('windows');
		}
		if (mobileCheck.smartphone()) {			
			$html.addClass('smartphone');
		}
		// if (mobileCheck.tablet) {			
		// 	$html.addClass('tablet');
		// }
		if (mobileCheck.all()) {
			// console.log('all-devices');	
			$html.addClass('all-devices');
		}
		// if (md.is('iPhone')) {
		// 	$html.addClass('iPhone');
		// }
		// if (md.is('iPad')) {
		// 	$html.addClass('iPad');
		// }

		var b = document.documentElement;
		b.className = b.className.replace('no-js', 'js');
		b.setAttribute("data-useragent",  navigator.userAgent);
		b.setAttribute("data-platform", navigator.platform );
	}

	var reset = function() {
		// console.log('reset');
		$html.removeClass('ios android blackBerry windows smartphone all-devices');
	}

	return {
		init: init,
		reset: reset
	}

})($, mobileCheck);

var Box = (function(){

	var $parent = $('.box-content');
	var $el;
	var $title;
	var _heightContainer;
	var _heightTitle;
	var _initPosition;

	var init = function() {

		$parent.each(function(){
			var $overlay = $(this).find('.box-overlay-wrapper');
			var _addPadding = parseInt($overlay.css('padding-top')) + parseInt($overlay.css('padding-bottom'));
			_heightContainer = $(this).outerHeight();
			_heightTitle = $(this).find('.box-title').outerHeight();
			_initPosition = _heightContainer - _heightTitle - _addPadding;

			$overlay.attr('data-start-position', _initPosition).css('top', _initPosition);

		
			$(this).on('mouseenter', function() {
				if($(window).innerWidth() > 768) {
					TweenMax.to($overlay, 0.5, {top: 0, ease: Power4.easeOut});
				}
			});

			$(this).on('mouseleave', function() {
				if($(window).innerWidth() > 768) {
					TweenMax.to($overlay, 0.5, {top: $overlay.data('start-position'), ease: Power4.easeOut});
				}
			});
		});
	}

	var resize = function() {

		$parent.each(function(){
			var $overlay = $(this).find('.box-overlay-wrapper');
			var _addPadding = parseInt($overlay.css('padding-top')) + parseInt($overlay.css('padding-bottom'));
			_heightContainer = $(this).outerHeight();
			_heightTitle = $(this).find('.box-title').outerHeight();
			_initPosition = _heightContainer - _heightTitle - _addPadding;

			$overlay.attr('data-start-position', _initPosition).css('top', _initPosition);
		});

	}

	return {
		init: init,
		resize: resize
	}

})();

var Masthead = (function(Helper){

	var $el = $('.masthead__slider');

	var init = function() {

		if ($('.masthead').length > 0) {

			if ($el.length > 0) {
				$el.on('init', function(e, slick) {
					// console.log('init');
			        var $firstAnimatingElements = $('div.masthead__slider__item:first-child').find('[data-animation]');
			        Helper.doAnimations($firstAnimatingElements);    
			    });

			    $el.on('beforeChange', function(e, slick, currentSlide, nextSlide) {
			    	// console.log('beforeChange');
	              	var $animatingElements = $('div.masthead__slider__item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
	              	Helper.doAnimations($animatingElements);    
	    		});

				$('.masthead__slider').slick({
			       // autoplay: true,
			       // autoplaySpeed: 5000,
			       fade: true,
			       // pauseOnHover: false,
			       prevArrow: '<button type="button" class="slick-prev">Previous</button>',
			       nextArrow: '<button type="button" class="slick-next">Next</button>'
			    });
			}
			
			// if large screen
			if ($('html').hasClass('all-devices')) {
				if ($('html').hasClass('phone') || $('body').hasClass('phone-view') ||
					$('html').hasClass('tablet') || $('body').hasClass('tablet-view')) {
					if (window.scrollY <= 0) {
						$('body').removeClass('sticky-nav');
					} else {
						$('body').addClass('sticky-nav');
					}
				}
			}

			$(window).on('resize.masthead', 
				$.debounce(250, function(){
					if (!$('.masthead').hasClass('static')) {
						$('.masthead__slider').slick('setPosition');
					}

					if ($('.ez-animate').length) {
						// console.log('exist');
						setTimeout(function(){
							// console.log('refresh all');
							Waypoint.refreshAll();
						}, 500);						
					}
				})
			);

			$(window).on('scroll', function() {
				if ($('html').hasClass('phone') || $('body').hasClass('phone-view') ||
					$('html').hasClass('tablet') || $('body').hasClass('tablet-view')) {
					var scroll_top = $(window).scrollTop();

					if (scroll_top <= 0) { // the detection!
						$('body').removeClass('sticky-nav');
					} else {
						$('body').addClass('sticky-nav');
					}
				}
			});

			setTimeout(function(){
				initInview();
			}, 500);			

			if ($('.masthead').hasClass('static')) {
				$('#js-header').addClass('static');
			}    			

		}
		
	}

	function initInview() {

		// if ($('html').hasClass('all-devices') || $('body').hasClass('tablet-view')) {			

			$('.masthead').on('inview', function(event, isInView) {
				// console.log('start');
				if (isInView) {
					// element is now visible in the viewport
					// console.log('element is now visible in the viewport');					
					$('body').addClass('masthead-inview');
					$('body').removeClass('masthead-outview');
				} else {
					// element has gone out of viewport
					// console.log('element has gone out of viewport');					
					$('body').removeClass('masthead-inview');
					$('body').addClass('masthead-outview');
				}
			});
			$('#js-main-nav-toggle').on('inview', function(event, isInView) {
				// console.log('start');
				if (isInView) {
					// element is now visible in the viewport
					// console.log('element is now visible in the viewport');					
					$('body').addClass('mobile-nav-inview');
					$('body').removeClass('mobile-nav-outview');
					// $('body').removeClass('mobile-nav-inview-init');
				} else {
					// element has gone out of viewport
					// console.log('element has gone out of viewport');					
					$('body').removeClass('mobile-nav-inview');
					$('body').addClass('mobile-nav-outview');
					// $('body').removeClass('mobile-nav-inview-init');
				}
			});			
		// }

	}

	return {
		init: init
	}

})(Helper);

var HotelCollection = (function(){

	var $el = $('#hotel-collection');
	var $elTrigger = $('.hotel-collection-trigger');
	var $elDropdown = $('.hotel-collection-dropdown');

	var init = function() {

		$elTrigger.on('click', function(e){
			e.preventDefault();
			if ($elDropdown.is(':visible')) {
				$elTrigger.removeClass('opened');
			} else {
				$elTrigger.addClass('opened');				
			}
			$elDropdown.fadeToggle({
				complete: function() {
					if ($elDropdown.is(':visible')) {						
						// Click Outside
						$(window).on('click.ui.topnav', function(e) {
			            	if ($(e.target).closest('#hotel-collection').length === 0) {
			                    $(window).off('click.ui.topnav');
			                    $elTrigger.removeClass('opened');
			                    $elDropdown.fadeOut();
			                }
			            });
					} else {						
						$(window).off('click.ui.topnav');
					}
				}
			});            
		});

		$el.on('inview', function(event, isInView) {
			if (!isInView) {
                $(window).off('click.ui.topnav');
                $elDropdown.fadeOut();
			}
		});

	}

	return {
		init: init
	}

})();

var TopNavOffCanvas = (function(){

	var $el = $('.top-nav-off-canvas__content');
	var $elTrigger = $('.top-nav-off-canvas-trigger');
	var _triggerHeight;
	var _elHeight;


	var init = function() {
		
		if ($el.length > 0) {

			_elHeight = $el.outerHeight();
			_triggerHeight = $elTrigger.outerHeight();

			// if ($('#ccm-toolbar').length > 0) {
			// 	_triggerHeight = $elTrigger.outerHeight() + $('#ccm-toolbar').outerHeight();
			// }
			// _triggerHeight = $elTrigger.outerHeight() - 1;

			$el.css('top', -_elHeight);

			$elTrigger.on('click', function(e){
				e.preventDefault();

				$('body').addClass('top-canvas-open');

				TweenMax.to($el, 0.5, {top: _triggerHeight, ease: Power4.easeOut, onComplete: function(){

					$el.addClass('opened');					

					// Click Outside
		            $(window).on('click.ui.topnav', function(e) {		            	
		            	// e.preventDefault();

		            	if ($(e.target).closest('.top-nav-off-canvas__content').length === 0) {

			                TweenMax.to($el, 0.5, {top: -_elHeight, ease: Power4.easeOut});
		                    $(window).off('click.ui.topnav');

		                    $el.removeClass('opened');
		                    $('body').removeClass('top-canvas-open');
		                }
		            });
				}});		
			});

			$elTrigger.on('focusin', function(e){
				e.preventDefault();

				$('body').addClass('top-canvas-open');

				TweenMax.to($el, 0.5, {top: _triggerHeight, ease: Power4.easeOut, onComplete: function(){

					$el.addClass('opened');					

					// Click Outside
		            $(window).on('click.ui.topnav', function(e) {		            	
		            	// e.preventDefault();

		            	if ($(e.target).closest('.top-nav-off-canvas__content').length === 0) {

			                TweenMax.to($el, 0.5, {top: -_elHeight, ease: Power4.easeOut});
		                    $(window).off('click.ui.topnav');

		                    $el.removeClass('opened');
		                    $('body').removeClass('top-canvas-open');
		                }
		            });
				}});		
			});

			$('.top-nav-off-canvas__item:last').on('focusout', function(e){
				TweenMax.to($el, 0.5, {top: -_elHeight, ease: Power4.easeOut});
                $(window).off('click.ui.topnav');

                $el.removeClass('opened');
                $('body').removeClass('top-canvas-open');
            });

            $('.top-nav-off-canvas-block').on('inview', function(event, isInView) {
				if (!isInView) {
					// console.log('not inview');
					if ($('body').hasClass('top-canvas-open')) {
						// console.log('hide');
						TweenMax.to($el, 0.5, {top: -_elHeight, ease: Power4.easeOut});
		                $(window).off('click.ui.topnav');

		                $el.removeClass('opened');
		                $('body').removeClass('top-canvas-open');
					}
				}
			});

			$(window).on('resize.top-canvas', 
				$.debounce(250, function(){
					if ($el.hasClass('opened')) {
						$el.css({
							'top': $elTrigger.outerHeight() - 1
						});					
					} else {
						$el.css({
							'top': -$el.outerHeight()
						});	
					}
				})
			);

		}

	}

	return {
		init: init
	}

	

})();


var Form = (function() {

	var init = function() {
		initSelect();
		initReadMore();
	}

	function initSelect() {
		$('select').selectric({
			arrowButtonMarkup: '<b class="selectric-button"></b>',
			onInit: function( element, obj ) {
				var select = $(element);
				var placeholder = select.attr('placeholder');
				var wrapper = select.closest('.selectric-wrapper');
				var label = wrapper.find('.label').addClass('placeholder');
				if ( label.text() !== placeholder ) {
				  label.text(placeholder);
				}
			},
			onChange: function( element ) {
				var select = $(element);
				var placeholder = select.attr('placeholder');
				var wrapper = select.closest('.selectric-wrapper');
				var label = wrapper.find('.label');

				if (label.hasClass('placeholder')) {
					label.removeClass('placeholder');
				}
			},
			nativeOnMobile: $('html').hasClass('tablet')?false:true
		});
	}

	function initReadMore() {

		var $parent;
		var $hiddenEl;
		var _labelOpen;
		var _labelClose;

		if ($('.read-more').length > 0) {

			// $('.read-more').css({
			// 	'min-width': $('.read-more').outerWidth()
			// });
			var $arrow = $('<span></span>').addClass('read-more-arrow');
			var $wrapperLabel = $('<span></span>').addClass('read-more-label');
			$('.read-more').each(function(){
				$wrapperLabel = $wrapperLabel.clone().html($(this).text());
				$(this).empty();
				$(this).append($wrapperLabel).append($arrow.clone());
			});

			$('.read-more').on('click', function(e) {
				e.preventDefault();

				_labelOpen = $(this).data('label-less');
				_labelClose = $(this).data('label-more');											
				$parent = $(this).closest('.post-content-wrapper');
				$hiddenEl = $parent.find('.post-hidden-content-block');

				$hiddenEl.slideToggle();
				$(this).toggleClass('opened');

				if ($(this).hasClass('opened')) {					
					$(this).children('.read-more-label').text(_labelOpen);
				} else {					
					$(this).children('.read-more-label').text(_labelClose);
				}
			});

			// $(window).on('resize.readmore', function() {
			// 	$('.read-more').css({
			// 		'min-width': $('.read-more').outerWidth()
			// 	});
			// });
		}

		

	}

	return {
		init: init
	}

})();

var Datepicker = (function() {
	var $arrival  = $('#arrival-date');
	var $departure  = $('#departure-date');

	var init = function() {
		if ($('#arrival-date').length > 0 && $('#departure-date').length > 0) {			

			var $label = $("<div>", {"class": "datepicker-label"});
			
			var tommorow = new Date();
			tommorow.setDate(tommorow.getDate() + 1);

			var aYearFromNow = new Date();
			aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

			$('.datepicker-wrapper').append($label);

			var arrivalDate = $('#arrival-date').datepicker({
				maxDate: aYearFromNow,
	    		minDate: new Date(),
	    		beforeShow: function() {
	    			$selected = $(this);
					$selected.prop('disabled', true);
	    		},
	    		onClose: function() {
	    			$selected = $(this);
					$selected.prop('disabled', false);   				
	    		},
	    		onSelect: function(dateText, obj) {
		        	var value = $.datepicker.formatDate( "D, M dd", new Date( dateText ) );
		        	var date = $(this).datepicker("getDate");

		        	$('#arrival-date').next().html(value);
		        		
		        	if (moment(dateText).isSameOrAfter($('#departure-date').datepicker( "getDate" ))) {	
		        		date.setDate(date.getDate() + 1);		        		
		        		$('#departure-date').datepicker( "option", "minDate", date );		        		
		        		$('#departure-date').datepicker( "setDate", date );

		        		var valueDate = $.datepicker.formatDate( "D, M dd", date );

		        		$('#departure-date').next().html(valueDate);
		        	} else {
		        		date.setDate(date.getDate() + 1);		        		
		        		$('#departure-date').datepicker( "option", "minDate", date );
		        	}
		        	// console.log('onSelect');
		        	// console.log($('#arrival-date').val());
		        	// for validation
		        	// if ($('#arrival-date').next().hasClass('validation-error')) {
		        	// 	$('#arrival-date').next().removeClass('validation-error');
		        	// }

		        	// if ($('#departure-date').val() != '') {
		        	// 	$('#booking-console').find('button').prop('disabled', false);
		        	// }
		        }
			});
		    

		    $('#arrival-date').next('.datepicker-label').on('click', function(){
	    	 	arrivalDate.datepicker( "show" );
		    });

			var departureDate = $('#departure-date').datepicker({
				maxDate: aYearFromNow,
	    		minDate: tommorow,
	    		beforeShow: function() {
	    			$selected = $(this);
					$selected.prop('disabled', true);
	    		},
	    		onClose: function() {
	    			$selected = $(this);
					$selected.prop('disabled', false);	    			
	    		},
		        onSelect: function(dateText, obj) {
		        	var value = $.datepicker.formatDate( "D, M dd", new Date( dateText ) );     	 
		        	$('#departure-date').next().html(value);

		        	// for validation
		        	// if ($('#departure-date').next().hasClass('validation-error')) {
		        	// 	$('#departure-date').next().removeClass('validation-error');
		        	// }

		        	// if ($('#arrival-date').val() != '') {
		        	// 	$('#booking-console').find('button').prop('disabled', false);
		        	// }
		        }
		    });

		    $('#arrival-date').next('.datepicker-label').html($('#arrival-date').attr('placeholder'));
		    $('#departure-date').next('.datepicker-label').html($('#departure-date').attr('placeholder'));

		    $('#departure-date').next('.datepicker-label').on('click', function(){
		    	departureDate.datepicker( "show" );
		    });

		    $(document).on('click', '.datepicker', function(e){
		    	e.preventDefault();
		    	e.stopPropagation();
		    	$(this).trigger('blur');	
		    });
		}
	}

	return {
		init: init
	}

})();
var Datepicker_backup = (function() {
	var $arrival  = $('#arrival-date');
	var $departure  = $('#departure-date');

	var init = function() {
		if ($('#arrival-date').length > 0 && $('#departure-date').length > 0) {			

			var $label = $("<div>", {"class": "datepicker-label"});
			
			var tommorow = new Date();
			tommorow.setDate(tommorow.getDate() + 1);

			var aYearFromNow = new Date();
			aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

			$('.datepicker-wrapper').append($label);

			var arrivalDate = new Pikaday({
				maxDate: aYearFromNow,
	    		minDate: new Date(),
		        field: document.getElementById('arrival-date'),
		        i18n: {
		        	previousMonth : 'Previous Month',
				    nextMonth     : 'Next Month',
				    months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
				    weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
				    weekdaysShort : ['Su','M','Tu','W','Th','F','Sa']
				},
		        onSelect: function(date) {
		        	var value = this.getMoment().format('ddd, MMM DD');
		        	$('#arrival-date').next().html(value);		        	

		        	if (moment(date).isSameOrAfter(departureDate.getDate())) {		        		
		        		date.setDate(date.getDate() + 1);
		        		departureDate.setMinDate(date);
		        		departureDate.setDate(date);
		        	} else {
		        		date.setDate(date.getDate() + 1);
		        		departureDate.setMinDate(date);
		        	}
		        }
		    });

		    

		    $('#arrival-date').next('.datepicker-label').on('click', function(){		    	
		    	arrivalDate.show();
		    });

			var departureDate = new Pikaday({
				maxDate: aYearFromNow,
	    		minDate: tommorow,
		        field: document.getElementById('departure-date'),
		        i18n: {
		        	previousMonth : 'Previous Month',
				    nextMonth     : 'Next Month',
				    months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
				    weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
				    weekdaysShort : ['Su','M','Tu','W','Th','F','Sa']
				},
		        onSelect: function(date) {
		        	var value = this.getMoment().format('ddd, MMM DD');
		        	$('#departure-date').next().html(value);
		        	// date.setDate(date.getDate() - 1);
		        	// arrivalDate.setMaxDate(date);
		        }
		    });

		 //    departureDate.setDate(tommorow);
			// arrivalDate.setDate(new Date());
		    $('#arrival-date').next('.datepicker-label').html($('#arrival-date').attr('placeholder'));
		    $('#departure-date').next('.datepicker-label').html($('#departure-date').attr('placeholder'));

		    $('#departure-date').next('.datepicker-label').on('click', function(){
		    	departureDate.show();
		    });

		}
		
	}

	return {
		init: init
	}

})();

var BookConsole = (function(){

	var init = function() {
		// initValidation();

		$('.js-check-rate').click(function(e) {
		    e.preventDefault();
		    $('#booking-console').trigger('submit');
		});

		$('.booking-console-form').on('inview', function(event, isInView) {
			// console.log('start');
			if ($('html').hasClass('phone') || $('body').hasClass('phone-view') ||
				$('html').hasClass('tablet') || $('body').hasClass('tablet-view')) {
				if (isInView) {
					// element is now visible in the viewport
					// console.log('element is now visible in the viewport');					
					$('body').addClass('booking-console-inview');
					$('body').removeClass('booking-console-outview');
				} else {
					// element has gone out of viewport
					// console.log('element has gone out of viewport');					
					$('body').removeClass('booking-console-inview');
					$('body').addClass('booking-console-outview');
				}
			}
			
		});

		if ($('html').hasClass('phone') || $('body').hasClass('phone-view')) {	
			$('select#children').selectric({
				onBeforeInit: function() {					
					$('select#children option:first').html('Children');
				}
			});
			$('select#adult').selectric({
				onBeforeInit: function() {
					$('select#adult option:first').html('Adults');
				}
			});
		}

		$(window).on('resize.booking-console', 
			$.debounce(250, function(){
				setTimeout(function(){
					if ($('html').hasClass('phone') || $('body').hasClass('phone-view')) {
						$('select#children option:first').html('Children');
						$('select#children').selectric('refresh');
						$('select#adult option:first').html('Adults');
						$('select#adult').selectric('refresh');
					} else {
						$('select#children option:first').html('Select Children');
						$('select#children').selectric('refresh');
						$('select#adult option:first').html('Select Adults');
						$('select#adult').selectric('refresh');
					}
				}, 500);			
			})
		);
		
	}

	function initValidation() {

		var $arrival  = $('#arrival-date');
		var $departure  = $('#departure-date');
		var $form = $('#booking-console');
		var $submit = $form.find('button');

		$('select#adult').on('selectric-change', function(e, element, selectric) {			
			$(element).parent().next().removeClass('validation-error');
		});

		$submit.on('click', function(e){
			if ($arrival.val() == '' || $departure.val() == '' || $('#adult').val() == null) {
				e.preventDefault();

				if ($arrival.val() == '') {
					$arrival.next('.datepicker-label').addClass('validation-error');
				}
				if ($departure.val() == '') {
					$departure.next('.datepicker-label').addClass('validation-error');
				}
				if ($('#adult').val() == null) {
					$('#adult').parent().next().addClass('validation-error');
				}
			} else {
				$form.submit();
			}

		});

	}

	return {
		init: init
	}

})();

var Sticky = (function() {

	var heightStickyHeader;

	var init = function() {

		if ($("#js-header").length > 0) {
			setTimeout(function(){
                if ($('html').hasClass('ccm-toolbar-visible')) {
                    $("#js-header").sticky({topSpacing:48});
                    $("#js-header").on('sticky-start', function() {
                        // $('#js-booking-console').sticky({topSpacing:118});
                        $('#js-booking-console').sticky({topSpacing:113});
                    });
                } else {
                    $("#js-header").sticky({topSpacing:0});
                    $("#js-header").sticky('update');
                    // $("#js-header").on('sticky-start', function() {
                        // $('#js-booking-console').sticky({topSpacing:70});
                        $('#js-booking-console').sticky({topSpacing:65});
                        $('#js-booking-console').sticky('update');
                    // });
                }

                $('#js-booking-console').on('sticky-end', function(){
                    setTimeout(function(){
                        $('#js-booking-console').sticky('update');
                    }, 500);
                });
			}, 200);			
		}
		
	}

	$(window).on('resize.sticky', 
		$.debounce(250, function(){
			setTimeout(function(){
				init();
			}, 200);			
			// }, 500);			
		})
	);

	// function getHeightSticky() {
	// 	var height = $("#js-header-sticky-wrapper").addClass('is-sticky').find('#js-header').outerHeight();
	// 	console.log(height);
	// }

	return {
		init: init
	}

})();

var SocialMedia = (function(){

	var init = function() {

		if ($('#js-social-media-large').length > 0) {
			if (!Foundation.MediaQuery.atLeast('large')) {

				$( ".instagram-block" ).append( $(".social-media-block") );

			} else {
				$('#js-social-media-large').append($( ".social-media-block" ));
			}

			$(window).on('resize.social', 
				$.debounce(250, function(){

					if (!Foundation.MediaQuery.atLeast('large')) {

						$( ".instagram-block" ).append( $(".social-media-block") );

					} else {
						$('#js-social-media-large').append($( ".social-media-block" ));
					}

				})
			);
		}

	}

	return {
		init: init
	}

})();

var MobileView = (function(){

	// var $toggle;
	var $clone;
	var $menuNav;
	var $header;

	var init = function() {
        initMenu();
	}

	function initMenu() {
		var $arrow = $('<span></span>').addClass('menu-arrow');

		$menuNav = $('.main-nav-mobile-list');
		// $toggle = $('#js-main-nav-toggle');

		// Create Element Arrow
		$('.main-nav-mobile-list li.has-sub').each(function(){
			$(this).children('a').append($arrow.clone());

			// console.log($(this).children('a').attr('href'));
			if ($(this).children('a').attr('href') == undefined || $(this).children('a').attr('href') == '#') {
				
			} else {
				$(this).children('a').addClass('clickable');
			}
		});

		// toggle onclick
		$('body').on('click', '.main-nav-toggle', function(e){
			e.preventDefault();

			$('body').addClass('mobile-nav-opened');
			// $menuNav.slideDown();
		});

		// Close onclick
		$('#js-main-nav-close').on('click', function(e){
			e.preventDefault();

			$('body').removeClass('mobile-nav-opened');
			// $menuNav.slideUp();
		});
		
		// Sub Menu onclick
		// $('.main-nav-mobile-list .has-sub > a span').on('click', function(e){
		$('body').on('click', '.main-nav-mobile-list .has-sub > a span', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			$(this).parent().parent().toggleClass('open');
			$(this).parent().parent('li').siblings().removeClass('open');
			$(this).parent().parent('li').siblings().children('ul').slideUp();
			$(this).parent().next().slideToggle();
		});

		$('body').on('click', '.main-nav-mobile-list .has-sub > a', function(e){
			var attr = $(this).attr('href');
			if(attr == undefined || attr == '#') {
				e.preventDefault();
			    $(this).parent().toggleClass('open');
				$(this).parent('li').siblings().removeClass('open');
				$(this).parent('li').siblings().children('ul').slideUp();
				$(this).next().slideToggle();
			}
		});
	}

	return {
		init: init
	}

})();

var MainNav = (function(){

	var $magicLine;
	var $el = $('.main-nav');
	var $selectedItem;
	var _position;
	var _width;

	var init = function() {

		// Backup
		// $('.main-nav .has-sub > a').on('click', function(e){
		// 	e.preventDefault();
		// 	var $parent = $(this).parent();
		// 	$parent.addClass('open');

		// 	$(window).on('click.ui.main-nav', function(e) {
  //           	// e.preventDefault();

  //           	if ($(e.target).closest($parent).length === 0) {

  //                   $(window).off('click.ui.topnav');

  //                  	$parent.removeClass('open');
  //               }
  //           });
		// });

		initMagicLine();

		// $('.main-nav > ul > li > a').on('mouseenter', function(e){
		$('.main-nav > ul > li > a').on('mouseenter', function(e){
			e.preventDefault();

			$selectedItem = $(this);
			// _position = $selectedItem.
			$magicLine.addClass('show');
			magicLineMoveTo($selectedItem.parent());
			// $magicLine.animate({

			// });
			
			// TweenMax.to($magicLine, 0.5, {top: 0, ease: Power4.easeOut});
			// $(this).parent().addClass('open');
			// $(this).next().fadeIn();
		}).on('mouseleave', function(e){
			e.preventDefault();

			// setTimeout(function(){
				$magicLine.removeClass('show');
			// }, 3000);
			
			// $(this).parent().removeClass('open');
			// $(this).next().fadeOut();
			
		}).on('focus', function(e){
			e.preventDefault();

			$selectedItem = $(this);
			$magicLine.addClass('show');
			magicLineMoveTo($selectedItem.parent());
		});

		$('.main-nav li.has-sub ul a').on('focusin', function(e){
			e.preventDefault();

			$(this).closest('.has-sub').addClass('open');
		});

		$('.main-nav li.has-sub ul a').on('focusout', function(e){
			e.preventDefault();

			$(this).closest('.has-sub').removeClass('open');
		});

	}

	function initMagicLine() {

		$magicLine = $('<div>').addClass('magic-line');
		$el.append($magicLine);

	}

	function magicLineMoveTo(_el) {
		_position = _el.position().left + parseInt(_el.css('margin-left'));
 		_width = _el.width();
 		// console.log(_position);
 		// console.log(_el.position().left);
 		// console.log(_el.css('margin-left'));

  		TweenMax.to($magicLine, 1, { left: _position, width: _width, ease: Expo.easeInOut});
	}

	return {
		init: init
	}

})();

var Setup = (function(){

	var md;

	var init = function() {
		setupOrientation();
		initDevices();

		setupResponsive();

		$(window).on('resize.setup', 
			$.debounce(250, function(){
				setupOrientation();
				resetDevices();
				initDevices();
				setupResponsive()    
			})
		); 
	}

	function setupOrientation() {
		if ($(window).width() < $(window).height()) {
		   $('body').addClass('portrait');
		   $('body').removeClass('landscape');
		}
		else {
		   $('body').addClass('landscape');
		   $('body').removeClass('portrait');
		}
	}

	function initDevices() {
        var md = new MobileDetect(navigator.userAgent),        
        grade = md.mobileGrade();
        Modernizr.addTest({
            mobile: !!md.mobile(),
            phone: !!md.phone(),
            tablet: !!md.tablet(),
            mobilegradea: grade === 'A'
        });
    }

    function resetDevices() {
    	delete Modernizr.mobile;
    	delete Modernizr.phone;
    	delete Modernizr.tablet;
    	delete Modernizr.mobilegradea;
    	$('html').removeClass("mobile no-mobile phone no-phone tablet no-tablet mobilegradea");
    }

    function setupResponsive() {
    	// if not mobile or not devices
    	if ($('html').hasClass('no-mobile')) {
    		if (Foundation.MediaQuery.atLeast('large')) {
	    		$('body').removeClass('phone-view tablet-view');
	    		$('html').removeClass('all-devices');
	    	} else if (Foundation.MediaQuery.atLeast('medium')) {
	    		$('body').removeClass('phone-view');
	    		$('body').addClass('tablet-view');
	    		$('html').addClass('all-devices');
	    	} else if (Foundation.MediaQuery.atLeast('small')) {
	    		$('body').removeClass('tablet-view');
	    		$('body').addClass('phone-view');
	    		$('html').addClass('all-devices');
	    	}
    	}    	
    }

	return {
		init: init
	}

})();

var Location = (function(){

	var locations = [];
	var map;
	var bounds;
	var infowindow;
	var marker;
	var ib;

	var init = function() {		
		if ($('#map').length > 0) {
			google.maps.event.addDomListener(window,'load',initMap);

			bindHandlers();
		}
	}

	function initMap() {
		getLocationFromList();

		// Create a map object and specify the DOM element for display.
        map = new google.maps.Map(document.getElementById('map'), {
          // center: {lat: 25.887962, lng: -80.129070},
          // zoom: 10
        });

        bounds = new google.maps.LatLngBounds();
        // infowindow = new google.maps.InfoWindow();

        var myOptions = {
			disableAutoPan: false
			,maxWidth: 0
			,pixelOffset: new google.maps.Size(30, -63)
			,zIndex: null
			// ,closeBoxMargin: "10px 2px 2px 2px"
			// ,closeBoxURL: "https://www.google.com/intl/en_us/mapfiles/close.gif"
			,closeBoxURL: PATH.images + "/raw/close.svg"
			// ,closeBoxURL: "images/raw/close.svg"
			// ,infoBoxClearance: new google.maps.Size(1, 1)
			,isHidden: false
			,pane: "floatPane"
			,enableEventPropagation: false
		};

		ib = new InfoBox(myOptions);

		var icon = {
			url: PATH.images + '/raw/marker.svg',    		
			size: new google.maps.Size(48, 48),				
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(24, 48),
			scaledSize: new google.maps.Size(48, 48),
			labelOrigin: new google.maps.Point(24, 20)
		};

		for (var i = 0; i < locations.length; i++) {  
	      	marker = new google.maps.Marker({
		        position: new google.maps.LatLng(locations[i]['latitude'], locations[i]['longitude']),
		        map: map,
		        icon: icon,
		        label: {
		        	text: locations[i]['label'],
		        	fontFamily: 'Frontage',
		        	fontSize: '12px',
		        	color: 'white'			        	
		        }
	      	});

	      	bounds.extend(marker.position);

	      	google.maps.event.addListener(marker, 'click', (function(marker, i) {
	        	return function() {
	        		var _title = locations[i]['title'];
	        		var _content = locations[i]['description'];
	        		var $html = '<h1 class="title">'+locations[i]['title']+'</h1><div class="description">'+locations[i]['description']+'</div>';
	          		ib.setContent($html);
	          		// infowindow.setContent(locations[i]['title']);
	          		// infowindow.open(map, marker);
	          		ib.open(map, this);
	        	}
	      	})(marker, i));
	    }

	    //now fit the map to the newly inclusive bounds
		map.fitBounds(bounds);

		//(optional) restore the zoom level after the map is done scaling
		var listener = google.maps.event.addListener(map, "idle", function () {
		    map.setZoom(12);
		    google.maps.event.removeListener(listener);
		});
	}

	function getLocationFromList() {

		$('#location-list li').each(function(){
			locations.push({
				title: $(this).data('title'),
				description: $(this).data('description'),
				longitude: $(this).data('longitude'),
				latitude: $(this).data('latitude'),
				label: $(this).find('.location-number').text()
			});
		});

	}

	function bindHandlers() {
		$('#location-list-dropdown').on('click', function(e){
			e.preventDefault();

			$('#location-list ul').slideToggle();
			$('#location-list').toggleClass('opened');
		});
	}

	var gotoLocation = function(lat, long) {

		if ($('#location-list').hasClass('opened')) {
			$('#location-list ul').slideToggle();
			$('#location-list').toggleClass('opened');
		}
		
		map.setCenter(new google.maps.LatLng(lat, long));
		map.setZoom(14);	
	}


	return {
		init: init,
		gotoLocation: gotoLocation
	}

})();

var Filter = (function(){

	var init = function() {

		if ($('.filter-content').length > 0) {

			buttonFilter.init();

			$('.filter-content').mixItUp({
			    controls: {
			    	enable: false
			    }
			});
		}

	}

	var buttonFilter = {
	  	// Declare any variables we will need as properties of the object
	  	$filters: null,
	  	groups: [],
	  	outputArray: [],
	  	outputString: '',
	  
	  	// The "init" method will run on document ready and cache any jQuery objects we will need.
	  	init: function(){
	    	var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "buttonFilter" object so that we can share methods and properties between all parts of the object.
	    
	    	self.$filters = $('.filter-block');
	    	self.$container = $('.filter-content');
	    
	    	if ($('html').hasClass('all-devices')) {
	    		self.$filters.find('.filter-mobile-content').each(function(){
			      	var $this = $(this);
			      
				    self.groups.push({
				        $inputs: $this.find('.filter'),
				        active: '',
				        tracker: false
				    });

			    });
	    	} else {
	    		self.$filters.find('.filter-checkboxes').each(function(){
			      	var $this = $(this);

			      	$this.find('.filter').prop('checked', true);

				    self.groups.push({
				        $inputs: $this.find('.filter'),
				        active: '',
				        tracker: false
				    });

			    });
	    	}
		    
		    self.bindHandlers();
	  	},
	  
	  	// The "bindHandlers" method will listen for whenever a button is clicked. 
	  	bindHandlers: function(){
	    	var self = this;

	    	// self.$filters.on('click', 'a', function(e){
		    //   	self.parseFilters();
	    	// });
	    	// console.log(self.$filters);
	    	self.$filters.find('input.filter').on('click', null, self, self.filterChange);
	    	self.$filters.find('select.filter').on('change', null, self, self.filterChange);
	    	// 
		    self.$filters.on('filterChange', '.filter', function(e){
		    	// console.log($(this));
		      	self.parseFilters();          
		    });
	  	},
	  	filterChange: function(e) {
	  		var self = e.data;
    		if ($(this).is(':checked')) {
    			if ($(this).data('filter') == 'all') {
    				// console.log('all');
    				self.$filters.find('input.filter').prop('checked', true);
    				$(this).prop('checked', true);
    				// console.log($(this).is(':checked'));
    			} else {
    				self.$filters.find('input.filter[data-filter="all"]').prop('checked', false);

    				// if others filter is select all, then All checkbox is checking
    				if (self.$filters.find('input.filter:checked').length >= self.$filters.find('input.filter').length - 1) {
    					self.$filters.find('input.filter[data-filter="all"]').prop('checked', true);
    				}

    				// console.log(self.$filters.find('input.filter:checked').length);
    			}
    		} else {
    			if ($(this).data('filter') == 'all') {
					// self.$filters.find('input.filter').prop('checked', true);
					self.$filters.find('input.filter').prop('checked', false);
					$(this).prop('checked', false);
    			} else {
    				// console.log(self.$filters.find('input.filter[data-filter="all"]:checked').length);
    				if (self.$filters.find('input.filter[data-filter="all"]:checked').length > 0) {
    					self.$filters.find('input.filter[data-filter="all"]').prop('checked', false);
    				}
    				// force if only one checkbox is checked (except 'all') uncheck, then check them all checkboxes
    				// if (self.$filters.find('input.filter:checked').length < 1) {
    				// 	self.$filters.find('input.filter').prop('checked', true);
    				// }
    				// self.$filters.find('input.filter[data-filter="all"]').prop('checked', false);
    			}
    		}

    		$(this).trigger('filterChange');
	  	},
	  	parseFilters: function(){
		    var self = this;
		 
		    // loop through each filter group and grap the active filter from each one.
		    for(var i = 0, group; group = self.groups[i]; i++){
		    	group.active = [];
		    	group.$inputs.each(function(){
		    		var $this = $(this);
		    		if($this.is('input[type="radio"]') || $this.is('input[type="checkbox"]')) {
		    			if($this.is(':checked') ) {		    				
	    					group.active.push($this.attr('data-filter'));
		    			}
		    		} else if($this.is('select')){	
		    			// console.log($this.val());
		    			group.active.push($this.val());
		    		} else if( $this.find('.selected').length > 0 ) {
		    			group.active.push($this.attr('data-filter'));
		    		}
		    	});
		    }
		    self.concatenate();
	  	},
	  
	  	concatenate: function(){
	    	var self = this;
	    	
	    	self.outputString = ''; // Reset output string

		    for(var i = 0, group; group = self.groups[i]; i++){
		      	self.outputString += group.active;
		    }
	    
		    // If the output string is empty, show all rather than none:    
		    // !self.outputString.length && (self.outputString = 'all');
		    // console.log(self.outputString.indexOf('all') != -1);
		    // if (!self.outputString.length) {
		    // 	self.outputString = 'all';
		    // }
			
	    	// Send the output string to MixItUp via the 'filter' method:    
			if(self.$container.mixItUp('isLoaded')){
				// console.log(self.outputString);
		    	self.$container.mixItUp('filter', self.outputString);
			}
	  	}
	};

	return {
		init: init
	}

})();

Location.init();

var Hack = (function(window) {

	var init = function() {

		// initMobileInview();
		inviewCrossDevices();
		initLazyLoad();
		initZoomImageForMobile();
		selectricLabelHack();		
		mainMenuHack();

		if (isSafari) {
		   	iOSBugsFixed();
		   	//  Object Fit - Polyfill
			// objectFitImages(null, {watchMQ: true});
		}
		// initGalleryHack();


		$(window).on('resize', 
			$.debounce(250, function(){
				mainMenuHack();
			})
		);

		
		
		if (isBadStockAndroid) {
	        mastheadHeightHack()
	        //  Object Fit - Polyfill
			objectFitImages(null, {watchMQ: true});
	        $(window).on('resize.hack', 
	        	$.debounce(250, function(){
	        		mastheadHeightHack();
	        	})
        	);
		}
		

		if ($('.side-content-block').length > 0) {
			$('.side-content-block').each(function(){
				if ($(this).find('.button').length == 2) {				
					$(this).addClass('has-two-buttons');
				}
			});
			
		}
	}

	function initMobileInview() {
		$('body').addClass('mobile-nav-inview-init');
	}

	function initLazyLoad() {
		if ($('.gallery-block .lazy').length > 0 ) {
			$('.gallery-block .lazy').Lazy({
				// scrollDirection: 'vertical',
				afterLoad: function(element) {
		            element.parent().addClass('loaded');
		            setSizeImage(element);
		        }
	        });

	        $(window).on('resize.gallery', 
				$.debounce(250, function(){
					var galleryWidth = $('.gallery-item img').parent().width();
					$('.gallery-item img').attr('width', galleryWidth);
					$('.gallery-item img').attr('height', galleryWidth);
					// $('.gallery-item img').css('width', galleryWidth);
					// $('.gallery-item img').css('height', galleryWidth);
				})
			);
		} 
		
		if ($('.initlazy .lazy').length > 0 ) {			
			$('.initlazy .lazy').Lazy({
				// scrollDirection: 'vertical',
				threshold: 0,
		        beforeLoad: function(e) {
		            // console.log("about to load " + e.attr("data-src"));
		        },
				afterLoad: function(element) {
		            element.parents('.initlazy').addClass('lazyloaded');
		            // console.log("loaded " + element.attr("src"));
		        },
		        onFinishedAll: function() {
		            // called once all elements was handled
		            objectFitImages(null, {watchMQ: true});
		        }
	        });
		} 

		// if ($('.masthead .lazy').length > 0 ) {
		// 	$('.masthead .lazy').Lazy({
		// 		scrollDirection: 'vertical',
		// 		onFinishedAll: function() {
		// 			$('.masthead.initlazy').removeClass('initlazy').addClass('lazyloaded');		            
		//         }
	 //        });
		// }
	}

	function initZoomImageForMobile() {
		if ($('html').hasClass('phone') || $('html').hasClass('tablet')) {
			$('.image-hover').on('inview', function(event, isInView) {
				// console.log('start');
				if (isInView) {
					// element is now visible in the viewport
					// console.log('element is now visible in the viewport');					
					$(this).addClass('inview');
				} else {
					// element has gone out of viewport
					// console.log('element has gone out of viewport');					
					$(this).removeClass('inview');
				}
			});
			$('.gallery-item').on('inview', function(event, isInView) {
				// console.log('start');
				if (isInView) {
					// element is now visible in the viewport
					// console.log('element is now visible in the viewport');					
					$(this).addClass('inview');
				} else {
					// element has gone out of viewport
					// console.log('element has gone out of viewport');					
					$(this).removeClass('inview');
				}
			});
		}		
	}

	function mastheadHeightHack() {
		var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		var mastheadHeight;
		if ($('.masthead').length > 0) {
			if ($('.masthead').hasClass('static')) {
				mastheadHeight = vH - 170;
				// mastheadHeight = vH - 260;
			} else {
				mastheadHeight = vH - 53;
			}
			
			$('.masthead__slider, .slick-list, .slick-track, .masthead__wrapper').height(mastheadHeight);
		}
	}

	function mainMenuHack() {
		if ($('html').hasClass('no-mobile') && Foundation.MediaQuery.atLeast('large')) {
			var space = 60;
			var headerWidth = $('#js-header').width();
			var logoWidth = 450; //hard code this to what we want the logo to be + the book now button
			var mainNavWidth = 0;

			$('#js-header .main-nav > ul > li').each(function(){
				mainNavWidth += $(this).outerWidth(true);
			});

			if ((logoWidth + mainNavWidth + space) > headerWidth) {
				$('body').addClass('tablet-view');
			} else {
				$('body').removeClass('tablet-view');
			}
		}

		// if desktop screen
		if (!$('body').hasClass('tablet-view') && !$('body').hasClass('phone-view') && !$('html').hasClass('mobile') ) {			
			setTimeout(function(){
				$('body').removeClass('mobile-nav-inview');
				$('body').removeClass('mobile-nav-outview');
				$('body').removeClass('booking-console-outview');				
				$('body').removeClass('masthead-outview');				
			}, 200);
		}
	}

	function selectricLabelHack() {
		$('.selectric-wrapper').each(function() {
			var id = $(this).find('.selectric-hide-select select').attr('id');			
			$(this).find('input.selectric-input').attr('aria-label', id);
		});
	}

	function setSizeImage(el) {
		el.attr('width', el.width());
		el.attr('height', el.height());
	}

	function iOSBugsFixed() {
		// $('input[readonly]').on('focus', function (ev) {
			// ev.preventDefault();
			// $(this).trigger('blur');
			// var oldScroll = $(window).scrollTop();

			// $( window ).one('scroll', function() {
			    // $(window).scrollTop( oldScroll ); //disable scroll just once
			// });

		    
		    
		    // window.scrollTo(0, 0);
        	// document.body.scrollTop = oldScroll;
		// });
	}

	function inviewCrossDevices() {

		if ($('html').hasClass('mobile') || $('body').hasClass('phone-view') || $('body').hasClass('tablet-view')) {			
			var scrollTop = $(window).scrollTop();
			var mobileNavView = $('#js-main-nav-toggle').position().top + $('#js-main-nav-toggle').outerHeight();
			var bookingConsoleView = $('.booking-console-form').position().top + $('.booking-console-form').outerHeight();
			var mastheadView = $('.masthead').position().top + $('.masthead').outerHeight();

			if (scrollTop > mobileNavView) {
				$('body').addClass('mobile-nav-outview');
			}
			if (scrollTop > bookingConsoleView) {
				$('body').addClass('booking-console-outview');
			}
			if (scrollTop > mastheadView) {
				$('body').addClass('masthead-outview');
			}
		}


	}

	$(window).on('resize.hack', 
		$.debounce(250, function(){
			setTimeout(function(){
				inviewCrossDevices();
			}, 500);
		})
	);


	return {
		init: init
	}

})(window);

$(document).ready(function(){

	Devices.init();
	Setup.init();	
	Form.init();
	Hack.init();
	Masthead.init();
	// TopNavOffCanvas.init();
	HotelCollection.init();
	MainNav.init();
	MobileView.init();
	Datepicker.init();
	Sticky.init();
	BookConsole.init();
	SocialMedia.init();	
	Filter.init();
	InitWaypointAnimations({
		offset: '100%',
		duration: '1.5s',
		ease: 'cubic-bezier(.39,.575,.565,1)'
	});

  if ((window.location.pathname.indexOf('hotels/miami')+1) && $('.masthead').length == 2) {
    $('.masthead').first().remove();
  }  
});
